import React, { useState, useContext } from 'react';
import './MainLayout.css';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

function MainLayout({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { auth, setAuth } = useContext(AuthContext);

    // Retrieve first_name, last_name, and username from location state
    const username = location.state?.username || '';

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      setAuth({ // Clear auth state on logout
          isAuthenticated: false,
          username: '',
          first_name: '',
          last_name: ''
      });
      navigate('/');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    // Generate initials from the firstName and lastName
    const initials = `${(auth.first_name || '').charAt(0).toUpperCase()}${(auth.last_name || '').charAt(0).toUpperCase()}`.trim();

    return (
        <div className={`main-layout ${sidebarOpen ? '' : 'sidebar-collapsed'}`}>
            <Sidebar collapsed={!sidebarOpen} />
            <div className="content-area">
                <div className="top-bar">
                    <FontAwesomeIcon icon={faBars} className="hamburger-icon" onClick={toggleSidebar} />
                    <div className="user-profile" onClick={toggleDropdown}>
                        <div className="profile-icon">{initials || 'N/A'}</div>
                        {dropdownOpen && (
                            <div className="dropdown-menu-header">
                                <p>{username}</p> {/* Display username from location state */}
                                <button onClick={handleLogout}>
                                    <i className="fas fa-sign-out-alt"></i> Log Out
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="main-content">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default MainLayout;
