const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchPhoneNumbers = async () => {
  const response = await fetch(`${baseURL}/phone-numbers`, {
    credentials: 'include'
  });
  if (!response.ok) throw new Error('Failed to fetch phone numbers');
  return response.json();
};



export const updatePhoneNumberStatus = async (id, currentStatus) => {
  const payload = {
    id,
    status: currentStatus === 'verified' ? 'unverified' : 'verified',
  };

  const response = await fetch(`${baseURL}/verify-phone-number`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    credentials: 'include',
  });

  if (!response.ok) throw new Error('Failed to update phone number status');
  return response.json();
};

export const registerPhoneNumber = async (formData) => {
  try {
    const response = await fetch(`${baseURL}/register-phone-number`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    });
    if (!response.ok) {
      if (response.status === 400) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message);
      }
      throw new Error('Failed to register phone number.');
    }
    return 'Phone number registered successfully.';
  } catch (error) {
    throw error;
  }
};
