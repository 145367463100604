// GET 

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchEnterprises = async () => {
  try {
    const response = await fetch(`${baseURL}/registered-enterprises`, {
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch enterprises');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};
export const fetchDisplayIdentityRecords = async (eid) => {
  try {
    const response = await fetch(`${baseURL}/display-identity-records?eid=${eid}`, {
      credentials: 'include',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch display-identity-records');
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

// POST
export const registerEnterprise = async (formData) => {
  try {
    const response = await fetch(`${baseURL}/register-enterprise`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      credentials: 'include',
    });
    return response.ok
      ? { success: true, message: 'Enterprise registered successfully.' }
      : { success: false, message: 'Failed to register enterprise.' };
  } catch (error) {
    console.error('Error:', error);
    return { success: false, message: 'An error occurred. Please try again.' };
  }
};

