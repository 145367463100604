import React, { useState, useEffect } from 'react';
import './RegisterPhoneNumber.css';
import { fetchDisplayIdentityRecords, fetchEnterprises } from '../../service/enterpriseService';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { registerPhoneNumber } from '../../service/phoneService';

function RegisterPhoneNumber() {
  const [formData, setFormData] = useState({
    phone: '',
    enterpriseId: '',
    status: 'unverified',
    dirid: '',
    op: '',
    vaid:'',
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [enterprises, setEnterprises] = useState([]);
  const [dirids, setDirids] = useState([]);
  const [error, setError] = useState('');
  const [enterpriseError, setEnterpriseError] = useState('');
  const [enterpriseIdSelected, setEnterpriseIdSelected] = useState('');

  useEffect(() => {
    const getEnterprises = async () => {
      try {
        const data = await fetchEnterprises();
        setEnterprises(data);
      } catch (err) {
        setError(err.message);
      }
    };

    getEnterprises();
  }, []);

  useEffect(() => {
    const getDisplayIdentityRecords = async () => {
      try {
        const diridsResponse = await fetchDisplayIdentityRecords(enterpriseIdSelected);
        setDirids(diridsResponse);
      } catch (err) {
        setError(err.message);
      }
    };
    getDisplayIdentityRecords();

  }, [enterpriseIdSelected]);

  const formatPhoneNumber = (phone) => {
    const validPhoneRegex = /^[+]?[\d]+$/;
  
    if (!validPhoneRegex.test(phone)) {
      throw new Error('Invalid phone number format.');
    }
  
    const phoneNumber = parsePhoneNumberFromString(phone, 'US'); 
  
    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.format('E.164');
    }
  
    throw new Error('Invalid phone number format.');
  };
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'enterpriseId') {
      setEnterpriseIdSelected(value);
      setEnterpriseError('');
    }
    if (name === 'dirid') {
      const selectedDirid = dirids.find((item) => item.dirid === value);
      setFormData((prevState) => ({
        ...prevState,
        dirid: value,
        op: selectedDirid ? selectedDirid.op : '',
        vaid: selectedDirid ? selectedDirid.vaid : '',
      }));
    }
    
  };
  const convertFormData = (data) => {
    return {
      tn: formatPhoneNumber(data.phone),
      eid: data.enterpriseId,
      status: data.status,
      op: data.op,
      dirid: data.dirid,
      vaid: data.vaid,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.enterpriseId) {
      setEnterpriseError('Please select an enterprise.');
      return;
    }
    try {
    const formattedData = convertFormData(formData);
      const successMessage = await registerPhoneNumber(formattedData);
      setResponseMessage(successMessage);
      setError('');
    } catch (error) {
      setResponseMessage('');
      setError(error.message);
    }
  };


  return (
    <div className="register-phone-number-container">
      <h2 className='mb-5'>Register phone number</h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group">
            <label htmlFor="entName">Phone</label>
            <input
              id="phone"
              name="phone"
              type="text"
              value={formData.phone}
              onChange={handleChange}
              className="form-control"
              placeholder='Enter phone number'
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="enterpriseId">Enterprise:</label>
            <select
              id="enterpriseId"
              name="enterpriseId"
              value={formData.enterpriseId}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">-- Select an enterprise --</option>
              {enterprises.map((item) => (
                <option key={item.id} value={item.eid}>{item.ent_name}</option>
              ))}
            </select>
            {enterpriseError && <p className="error-message">{enterpriseError}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="enterpriseId">Dirid:</label>
            <select
              id="dirid"
              name="dirid"
              value={formData.dirid}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="">-- Select an item --</option>
              {dirids.map((item) => (
                <option key={item.id} value={item.dirid}>{item.nam}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="entName">OP</label>
            <input
             id="op"
             name="op"
             type="text"
             value={formData.op}
             onChange={handleChange}
             className="form-control"
             readOnly
             disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="entName">Vaid</label>
            <input
             id="vaid"
             name="vaid"
             type="text"
             value={formData.vaid}
             onChange={handleChange}
             className="form-control"
             readOnly
             disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="status">Status:</label>
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="unverified">Unverified</option>
              <option value="verified">Verified</option>
            </select>
          </div>

        </div>

        <div className="button-container mt-4">
          <button type="submit" className="btn-indigo">Register</button>
        </div>
      </form>
      {responseMessage && <p className="response-message mt-3">{responseMessage}</p>}
      {error && <p className="response-message-error mt-3">{error}</p>}
    </div>
  );
}

export default RegisterPhoneNumber;