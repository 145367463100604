import React, { useState, useEffect } from 'react';
import './UploadDIRs.css';

const baseURL = process.env.REACT_APP_API_BASE_URL;

function UploadDIRs() {
  const [file, setFile] = useState(null);
  const [enterpriseName, setEnterpriseName] = useState('');
  const [enterprises, setEnterprises] = useState([]);
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchEnterprises();
  }, []);

  const fetchEnterprises = async () => {
    try {
      const response = await fetch(`${baseURL}/registered-enterprises`, {
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        setEnterprises(data);
      } else {
        console.error('Failed to fetch enterprises');
        setMessage('Failed to load enterprises. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching enterprises:', error);
      setMessage('An error occurred while loading enterprises.');
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log('Selected file:', selectedFile);
    if (selectedFile) {
      if (selectedFile.type === 'text/csv' || selectedFile.name.endsWith('.csv')) {
        setFile(selectedFile);
        setMessage(`File selected: ${selectedFile.name}`);
      } else {
        setMessage('Please select a CSV file');
        setFile(null);
      }
    } else {
      setMessage('No file selected');
      setFile(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submit clicked. File:', file, 'Enterprise:', enterpriseName);

    if (!file) {
      setMessage('Please select a file');
      return;
    }
    if (!enterpriseName) {
      setMessage('Please select an enterprise');
      return;
    }

    setUploading(true);
    setMessage('Uploading...');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('enterprise_name', enterpriseName);

    try {
      const response = await fetch(`${baseURL}/api/upload-dirs`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      const result = await response.json();
      console.log('Upload response:', result);

      if (response.ok) {
        setMessage(`File uploaded successfully. 
            Inserted: ${result.insertedCount}, 
            Updated: ${result.updatedCount}, 
            Skipped: ${result.skippedCount} out of ${result.totalProcessed} total rows.`);
        if (result.skippedRows.length > 0) {
        setMessage(prevMessage => `${prevMessage} 
            Skipped rows: ${result.skippedRows.map(row => `Line ${row.lineNumber}: ${row.nam} (${row.op})`).join(', ')}`);
          }
        setFile(null);
        setEnterpriseName('');
        // Reset file input
        const fileInput = document.getElementById('file');
        if (fileInput) fileInput.value = '';
      } else {
        setMessage(result.message || 'An error occurred during upload');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage(`An error occurred while uploading the file: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="upload-dirs-container">
      <h2>Upload DIRs</h2>
      <form onSubmit={handleSubmit} className="upload-dirs-form">
        <div className="upload-dirs-form-group">
          <label htmlFor="enterprise">Select Enterprise:</label>
          <select
            id="enterprise"
            value={enterpriseName}
            onChange={(e) => {
              setEnterpriseName(e.target.value);
              console.log('Enterprise selected:', e.target.value);
            }}
            required
            className="upload-dirs-select"
          >
            <option value="">Select an enterprise</option>
            {enterprises.map((enterprise) => (
              <option key={enterprise.eid} value={enterprise.ent_name}>
                {enterprise.ent_name}
              </option>
            ))}
          </select>
        </div>
        <div className="upload-dirs-form-group">
          <label htmlFor="file">Upload CSV File:</label>
          <div className="upload-dirs-input-with-button">
            <input
              type="file"
              id="file"
              accept=".csv"
              onChange={handleFileChange}
              required
              className="upload-dirs-file-input"
            />
            <button type="submit" className="btn-indigo" disabled={uploading}>
              {uploading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </div>
      </form>
      <div className="upload-dirs-info-message">
        <p>Please upload a CSV file (max 10MB) with the following columns in order:</p>
        <p>op, nam, icn_url, icn_rcdi, crns</p>
        <p>The CSV file should not include column headers.</p>
        <p>The 'op' column should contain 'A' or 'a' for add, 'D' or 'd' for delete.</p>
        <p>The 'crns' column may contain multiple values separated by semicolons.</p>
        <p>Example row: A,Acme Corp Customer Service,https://example.com/acme_icon.png,sha256-AC45678tuvwx,Order Assistance;Return Process</p>
      </div>
      {message && <p className="upload-dirs-message">{message}</p>}
    </div>
  );
}

export default UploadDIRs;
