import React, { useState } from 'react';
import './RegisterEnterprise.css';
import { registerEnterprise } from '../../service/enterpriseService';

function RegisterEnterprise() {
  const [formData, setFormData] = useState({
    eid: '',
    entName: '',
    email: '',
    phone: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    country: '',
    postcode: '',
    status: 'unverified', // Default to 'unverified'
    orgType: 'A', // Default to 'A'
    vaid: '',
    ospids: '',
    roleType: 'E', // Default to 'E'
  });
  const [responseMessage, setResponseMessage] = useState('');

  // Function to simulate EID generation
  const generateEID = () => {
    const uniqueEID = [...Array(35)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('');
    setFormData({ ...formData, eid: uniqueEID });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const convertFormData = (data) => {
    return {
      eid: data.eid,
      ent_name: data.ent_name,
      email: data.email,
      phone: data.phone,
      street1: data.street1,
      street2: data.street2,
      city: data.city,
      state: data.state,
      country: data.country,
      postcode: data.postcode,
      status: data.status,
      org_type: data.orgType,
      vaid: data.vaid,
      ospids: formData.ospids.split(',').map((ospid) => ospid.trim()),
      role_type: data.roleType,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = convertFormData(formData);
    const result = await registerEnterprise(formattedData);
    setResponseMessage(result.message);
  };
  
  return (
    <div className="container register-enterprise-container">
      <h2>Register an enterprise</h2>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* Left Column */}
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="eid">Enterprise ID (EID):</label>
              <div className="input-with-button">
                <input
                  id="eid"
                  name="eid"
                  type="text"
                  value={formData.eid}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
                <button type="button" className="btn-indigo" onClick={generateEID}>
                  Generate
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="entName">Enterprise Name:</label>
              <input
                id="entName"
                name="ent_name"
                type="text"
                value={formData.ent_name}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="vaid">VAID:</label>
              <input
                id="vaid"
                name="vaid"
                type="text"
                value={formData.vaid}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="ospids">OSPIDs:</label>
              <input
                id="ospids"
                name="ospids"
                type="text"
                value={formData.ospids}
                onChange={handleChange}
                className="form-control"
                placeholder="Comma-separated values"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number:</label>
              <input
                id="phone"
                name="phone"
                type="text"
                value={formData.phone}
                onChange={handleChange}
                className="form-control"
                placeholder="Phone"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                className="form-control"
                placeholder="Email"
                required
              />
            </div>
            <div className="form-group d-flex justify-content-between align-items-center">
              <div className="flex-fill me-3">
                <label htmlFor="orgType">Organization Type:</label>
                <select
                  id="orgType"
                  name="orgType"
                  value={formData.orgType}
                  onChange={handleChange}
                  className="form-control"
                  required
                >
                  <option value="A">Commercial Enterprise</option>
                  <option value="B">Government/Public Service</option>
                  <option value="C">Public Charity/Non-profit</option>
                </select>
              </div>
              <div className="flex-fill ms-3">
                <label htmlFor="roleType">Role Type:</label>
                <select
                  id="roleType"
                  name="roleType"
                  value={formData.roleType}
                  onChange={handleChange}
                  className="form-control"
                  required
                >
                  <option value="E">Enterprise</option>
                  <option value="B">BPO</option>
                </select>
              </div>
            </div>

          </div>

          {/* Right Column */}
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="street1">Street 1:</label>
              <input
                id="street1"
                name="street1"
                type="text"
                value={formData.street1}
                onChange={handleChange}
                className="form-control"
                placeholder="Enterprise Address Street 1"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="street2">Street 2:</label>
              <input
                id="street2"
                name="street2"
                type="text"
                value={formData.street2}
                onChange={handleChange}
                className="form-control"
                placeholder="Enterprise Address Street 2"
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City:</label>
              <input
                id="city"
                name="city"
                type="text"
                value={formData.city}
                onChange={handleChange}
                className="form-control"
                placeholder="City"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">State:</label>
              <input
                id="state"
                name="state"
                type="text"
                value={formData.state}
                onChange={handleChange}
                className="form-control"
                placeholder="State"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="country">Country:</label>
              <input
                id="country"
                name="country"
                type="text"
                value={formData.country}
                onChange={handleChange}
                className="form-control"
                placeholder="Country"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="postcode">Postcode:</label>
              <input
                id="postcode"
                name="postcode"
                type="text"
                value={formData.postcode}
                onChange={handleChange}
                className="form-control"
                placeholder="Postcode"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="status">Status:</label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="form-control"
                required
              >
                <option value="unverified">Unverified</option>
                <option value="verified">Verified</option>
              </select>
            </div>

          </div>
        </div>

        <div className="button-container mt-4 ">
          <button type="submit" className="btn-indigo">Register</button>
        </div>
      </form>
      {responseMessage && <p className="response-message mt-3">{responseMessage}</p>}
    </div>
  );
}

export default RegisterEnterprise;