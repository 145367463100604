import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { fetchPhoneNumbers, updatePhoneNumberStatus } from '../../service/phoneService';
import { fetchEnterprises } from '../../service/enterpriseService';

function PhoneNumbers() {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [phoneNumbersPerPage] = useState(10);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const phoneData = await fetchPhoneNumbers();
      const enterpriseData = await fetchEnterprises();
      setPhoneNumbers(phoneData);
      setEnterprises(enterpriseData);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedPhoneNumbers = [...phoneNumbers].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const indexOfLastPhoneNumbers = currentPage * phoneNumbersPerPage;
  const indexOfFirstPhoneNumbers = indexOfLastPhoneNumbers - phoneNumbersPerPage;
  const currentPhoneNumbers = sortedPhoneNumbers.slice(indexOfFirstPhoneNumbers, indexOfLastPhoneNumbers);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const getEnterpriseName = (enterpriseId) => {
    const enterprise = enterprises.find(ent => ent.eid === enterpriseId);
    return enterprise ? enterprise.ent_name : 'N/A';
  };

  const handleVerifyPhoneNumber = async (id, currentStatus) => {
    try {
      const updatedData = await updatePhoneNumberStatus(id, currentStatus);
      setPhoneNumbers(updatedData.data);
    } catch (err) {
      setError(err.message);
    }
  };
  return (
    <div className="registered-enterprises-container">
      <div className="header-container">
        <h2>Phone numbers</h2>
        <a href="/register-phone-number"><button type="button" className="btn-indigo" >
          Create
        </button></a>
      </div>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          <div className="table-container">
            <table className="enterprise-table">
              <thead>
                <tr>
                  {[
                    { key: 'phone', label: 'Phone' },
                    { key: 'enterprise_id', label: 'Enterprise Id' },
                    { key: 'enterprise_name', label: 'Enterprise Name' },
                    { key: 'status', label: 'Status' }
                  ].map(({ key, label }) => (
                    <th key={key} onClick={() => handleSort(key)}>
                      {label}
                      <FontAwesomeIcon
                        icon={sortField === key ? (sortDirection === 'asc' ? faSortUp : faSortDown) : faSort}
                        className="sort-icon"
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
              {currentPhoneNumbers.map((item) => (
                  <tr key={item.id}>
                    <td className="centered">{item.tn}</td>
                    <td className="centered">{item.eid}</td>
                    <td className="centered">{getEnterpriseName(item.eid)}</td>
                    <td className="centered">
                      <button
                        onClick={() => handleVerifyPhoneNumber(item.id, item.status)}
                        className={item.status === 'verified' ? 'status-verified' : 'status-unverified'}
                      >
                        {item.status === 'verified' ? 'Verified' : 'Unverified'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            {Array.from({ length: Math.ceil(phoneNumbers.length / phoneNumbersPerPage) }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => paginate(i + 1)}
                className={currentPage === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default PhoneNumbers;
