import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Login.css';
import { AuthContext } from '../../AuthContext';

const baseURL = process.env.REACT_APP_API_BASE_URL;

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useContext(AuthContext);

  const from = location.state?.from || '/registered-enterprises';

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Password validation regex
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{10,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validate username (email)
    if (!emailRegex.test(username)) {
      setError('Please enter a valid email address.');
      return;
    }

    // Validate password
    if (!passwordRegex.test(password)) {
      setError('Password must be at least 10 characters long and include a number and a special character.');
      return;
    }

    try {
      const response = await fetch(`${baseURL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include'
      });

      const data = await response.json();

      if (data.message === 'Login successful') {
        setAuth({
          isAuthenticated: true,
          username: data.username,
          first_name: data.first_name,
          last_name: data.last_name
        });
        navigate(from, { replace: true });
      } else {
        setError('Invalid credentials');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label>Email Address</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="button-container">
          <button type="submit">Login</button>
        </div>
      </form>
    </div>
  );
}

export default Login;
