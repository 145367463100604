import React, { createContext, useState, useEffect, useCallback } from 'react';
import { checkSessionStatus } from './utils/sessionUtils';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    username: '',
    first_name: '',
    last_name: ''
  });

  const checkSession = useCallback(async () => {
    const isAuthenticated = await checkSessionStatus();
    if (!isAuthenticated) {
      setAuth({
        isAuthenticated: false,
        username: '',
        first_name: '',
        last_name: ''
      });
    }
    return isAuthenticated;
  }, []);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, checkSession }}>
      {children}
    </AuthContext.Provider>
  );
};