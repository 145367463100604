import React from 'react';
import './Dashboard.css';
import'../../../src/'

function Dashboard() {
  //console.log('Dashboard component loaded');
  return (
    <div>
      <h1 className='mb-8 text-3xl font-bold'>Dashboard</h1>
      <p>Welcome to the dashboard.</p>
    </div>

  );
}

export default Dashboard;
