// src/utils/sessionUtils.js

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const checkSessionStatus = async () => {
    try {
      const response = await fetch(`${baseURL}/check-session`, {
        credentials: 'include'
      });
      const data = await response.json();
      return data.isAuthenticated;
    } catch (error) {
      console.error('Error checking session status:', error);
      return false;
    }
  };
