import React, { useEffect, useState } from 'react';
import './Vetting.css';

const baseURL = process.env.REACT_APP_API_BASE_URL;

function Vetting() {
  const [vettingData, setVettingData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVettingData = async () => {
      try {
        const response = await fetch(`${baseURL}/vetting-data`, {
          credentials: 'include'  // Add this line
        });
        if (!response.ok) {
          throw new Error('Failed to fetch vetting data');
        }
        const data = await response.json();
        setVettingData(data);
      } catch (err) {
        console.error('Error fetching vetting data:', err);
        setError(err.message);
      }
    };

    fetchVettingData();
  }, []);

  return (
    <div className="vetting-container">
      <h2>Vetting</h2>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        // Render your vetting data here
        <p>Vetting data loaded successfully.</p>
      )}
    </div>
  );
}

export default Vetting;
