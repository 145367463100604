import React from 'react';
import './UploadTNRs.css';

function UploadTNRs() {
  return (
    <div className="upload-tnrs-container">
      <h2>Upload TNRs</h2>
      {/* Add your form and logic for uploading TNRs here */}
    </div>
  );
}

export default UploadTNRs;