import Dashboard from './pages/Dasboard/Dashboard';
import RegisteredEnterprises from './pages/Enterprise/RegisteredEnterprises';
import RegisterEnterprise from './pages/Enterprise/RegisterEnterprise';
import InviteUsers from './pages/InviteUsers/InviteUsers';
import Login from './pages/Login/Login';
import PhoneNumbers from './pages/PhoneNumber/PhoneNumbers';
import RegisterPhoneNumber from './pages/PhoneNumber/RegisterPhoneNumber';
import RegisterUser from './pages/User/RegisterUser';
import Vetting from './pages/Vetting/Vetting';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import MainLayout from './components/layout/MainLayout';
import './App.css';
import UploadDIRs from './pages/Vetting/UploadDIRs';
import UploadTNRs from './pages/Vetting/UploadTNRs';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<MainLayout><Dashboard /></MainLayout>} />
          <Route path="/register-enterprise" element={<MainLayout><RegisterEnterprise /></MainLayout>} />
          <Route path="/registered-enterprises" element={<MainLayout><RegisteredEnterprises /></MainLayout>} />
          <Route path="/phone-numbers" element={<MainLayout><PhoneNumbers /></MainLayout>} />
          <Route path="/register-phone-number" element={<MainLayout><RegisterPhoneNumber /></MainLayout>} />
          <Route path="/upload-dirs" element={<MainLayout><UploadDIRs /></MainLayout>} />
          <Route path="/upload-tnrs" element={<MainLayout><UploadTNRs /></MainLayout>} />
          <Route path="/invite-users" element={<MainLayout><InviteUsers /></MainLayout>} />
          <Route path="/register/:token" element={<MainLayout><RegisterUser /></MainLayout>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;