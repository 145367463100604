import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import './InviteUsers.css';

const baseURL = process.env.REACT_APP_API_BASE_URL;

function InviteUsers() {
  const [email, setEmail] = useState('');
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [deleteMessage, setDeleteMessage] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [sortField, setSortField] = useState('email');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(8);

  useEffect(() => {
    fetchInvitedUsers();
  }, []);

  const fetchInvitedUsers = async () => {
    try {
      const response = await fetch(`${baseURL}/invited-users`, {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch invited users');
      }
      const data = await response.json();
      setInvitedUsers(data);
    } catch (err) {
      console.error('Failed to fetch invited users', err);
    }
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setDeleteMessage('');

    try {
      const response = await fetch(`${baseURL}/invite-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
        credentials: 'include'
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Invite sent successfully');
        setEmail('');
        fetchInvitedUsers();
      } else {
        setError(data.message || 'Failed to send invite');
      }
    } catch (err) {
      console.error('Failed to send invite', err);
      setError('An error occurred. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    setDeleteMessage('');
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${baseURL}/delete-user/${id}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (response.ok) {
        setDeleteMessage('User deleted successfully');
        fetchInvitedUsers();
      } else {
        const errorData = await response.json();
        setDeleteMessage(errorData.message || 'Failed to delete user');
      }
    } catch (err) {
      console.error('Failed to delete user', err);
      setDeleteMessage('An error occurred. Please try again.');
    }
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredUsers = invitedUsers.filter(user => 
    statusFilter === 'All' || user.status === statusFilter
  );

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedUsers.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="invite-users-container">
        <h2>Invite Users</h2>
        <form onSubmit={handleInvite} className="invite-users-form">
          <div className="form-group">
            <label>Email Address:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Send Invite</button>
            {error && <div className="message error">{error}</div>}
            {success && <div className="message success">{success}</div>}
          </div>
        </form>
      </div>

      <div className="invited-users-table-container">
        <h3>
          Invited Users
          {deleteMessage && (
            <span
              className={`message ${deleteMessage.includes('successfully') ? 'success' : 'error'}`}
            >
              {deleteMessage}
            </span>
          )}
        </h3>
        <div className="filter-container">
          <label htmlFor="status-filter">Filter by Status: </label>
          <select 
            id="status-filter" 
            value={statusFilter} 
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Invited">Invited</option>
            <option value="Registered">Registered</option>
          </select>
        </div>
       
        {currentUsers.length > 0 && (
          <>
            <div className="table-container">
              <table className="invited-users-table">
                <thead>
                  <tr>
                    <th onClick={() => handleSort('email')}>
                      Email
                      <FontAwesomeIcon 
                        icon={sortField === 'email' ? (sortDirection === 'asc' ? faSortUp : faSortDown) : faSort} 
                        className="sort-icon"
                      />
                    </th>
                    <th onClick={() => handleSort('status')}>
                      Status
                      <FontAwesomeIcon 
                        icon={sortField === 'status' ? (sortDirection === 'asc' ? faSortUp : faSortDown) : faSort} 
                        className="sort-icon"
                      />
                    </th>
                    <th onClick={() => handleSort('invited_by')}>
                      Invited By
                      <FontAwesomeIcon 
                        icon={sortField === 'invited_by' ? (sortDirection === 'asc' ? faSortUp : faSortDown) : faSort} 
                        className="sort-icon"
                      />
                    </th>
                    <th>Invitation Link</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user) => (
                    <tr key={user.id}>
                      <td>{user.email}</td>
                      <td>{user.status}</td>
                      <td>{user.invited_by}</td>
                      <td>
                        {user.status === 'Invited' ? (
                          <Link to={`/register/${user.invitation_token}`}>
                            Register
                          </Link>
                        ) : (
                          'N/A'
                        )}
                      </td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="delete-icon"
                          onClick={() => handleDelete(user.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pagination">
              {pageNumbers.map(number => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={currentPage === number ? 'active' : ''}
                >
                  {number}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default InviteUsers;
