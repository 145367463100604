import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBuilding, faClipboardCheck, faUsers, faChevronDown, faChevronUp, faFileAlt, faListAlt, faUserPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import favicon from '../../favicon.ico';
import secureLogix from '../../securelogix-logo.svg';
import './Sidebar.css';

function Sidebar({ collapsed }) {
  const [expandedCategories, setExpandedCategories] = useState({
    onboarding: false,
    userManagement: false,
  });

  const toggleCategory = (category) => {
    setExpandedCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-title">
        {!collapsed ? (
          <img src={secureLogix} alt="Logo" className="sidebar-icon-text" />
        ) : (
          <img src={favicon} alt="Logo" className="sidebar-icon" />
        )}

      </div>
      <nav>
        <ul>
          <li>
            <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'active' : ''}> {/* Use NavLink */}
              <FontAwesomeIcon icon={faHome} className="sidebar-icon" />
              {!collapsed && <span>Dashboard</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="/registered-enterprises" className={({ isActive }) => isActive ? 'active' : ''}> {/* Use NavLink */}
              <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
              {!collapsed && <span>Registered Enterprises</span>}
            </NavLink>
          </li>
          <li>
            <NavLink to="/phone-numbers" className={({ isActive }) => isActive ? 'active' : ''}> {/* Use NavLink */}
              <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
              {!collapsed && <span>Phone numbers</span>}
            </NavLink>
          </li>
          <li>
            <div className="category-header" onClick={() => toggleCategory('vetting')}>
              <FontAwesomeIcon icon={faClipboardCheck} className="sidebar-icon" />
              {!collapsed && <span>Vetting</span>}
              {!collapsed && (
                <FontAwesomeIcon icon={expandedCategories.vetting ? faChevronUp : faChevronDown} className="chevron-icon" />
              )}
            </div>
            {expandedCategories.vetting && (
              <ul className="submenu">
                <li>
                  <NavLink to="/upload-dirs" className={({ isActive }) => isActive ? 'active' : ''}>
                    <FontAwesomeIcon icon={faUpload} className="sidebar-icon" />
                    {!collapsed && <span>Upload DIRs</span>}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/upload-tnrs"className={({ isActive }) => isActive ? 'active' : ''}>
                    <FontAwesomeIcon icon={faUpload} className="sidebar-icon" />
                    {!collapsed && <span>Upload TNRs</span>}
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div className="category-header" onClick={() => toggleCategory('userManagement')}>
              <FontAwesomeIcon icon={faUsers} className="sidebar-icon" />
              {!collapsed && <span>User Management</span>}
              {!collapsed && (
                <FontAwesomeIcon icon={expandedCategories.userManagement ? faChevronUp : faChevronDown} className="chevron-icon" />
              )}
            </div>
            {expandedCategories.userManagement && (
              <ul className="submenu">
                <li>
                  <NavLink to="/invite-users">
                    <FontAwesomeIcon icon={faUserPlus} className="sidebar-icon" />
                    {!collapsed && <span>Invite Users</span>}
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;