import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faCopy } from '@fortawesome/free-solid-svg-icons';
import './RegisteredEnterprises.css';

const baseURL = process.env.REACT_APP_API_BASE_URL;

function RegisteredEnterprises() {
  const [enterprises, setEnterprises] = useState([]);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [enterprisesPerPage] = useState(10);
  const [copiedMessage, setCopiedMessage] = useState('');

  useEffect(() => {
    const fetchEnterprises = async () => {
      try {
	const response = await fetch(`${baseURL}/registered-enterprises`, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error('Failed to fetch registered enterprises');
        }
        const data = await response.json();
        setEnterprises(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchEnterprises();
  }, []);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedEnterprises = [...enterprises].sort((a, b) => {
    if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const indexOfLastEnterprise = currentPage * enterprisesPerPage;
  const indexOfFirstEnterprise = indexOfLastEnterprise - enterprisesPerPage;
  const currentEnterprises = sortedEnterprises.slice(indexOfFirstEnterprise, indexOfLastEnterprise);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedMessage(`Copied ${field}!`);
      setTimeout(() => setCopiedMessage(''), 1500);
    });
  };

  const handleVerifyEnterprise = async (eid, currentStatus) => {
    try {
      const payload = {
        eid,
        status: currentStatus === 'verified' ? 'unverified' : 'verified',
      };

      const response = await fetch(`${baseURL}/verify-enterprise`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to update enterprise status');
      }

      const updatedEnterprise = await response.json();
      console.log('Updated enterprise:', updatedEnterprise.data);

      // Update the enterprises state with the new data
      setEnterprises(updatedEnterprise.data);



    } catch (error) {
      setError(error.message);
    }
  };
  console.log('Response2:', enterprises);
  return (
    <div className="registered-enterprises-container">
      <div className="header-container">
        <h2>Registered Enterprises</h2>
        {copiedMessage && <div className="copied-message">{copiedMessage}</div>}
        <a href="/register-enterprise"><button type="button" className="btn-indigo" >
          Create enterprise
        </button></a>
      </div>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          <div className="table-container">
            <table className="enterprise-table">
              <thead>
                <tr>
                  {[
                    { key: 'eid', label: 'Ent. ID' },
                    { key: 'ent_name', label: 'Ent. Name' },
                    { key: 'org_type', label: 'Org Type' },
                    { key: 'vaid', label: 'VA ID' },
                    { key: 'ospids', label: 'OSP ID' },
                    { key: 'role_type', label: 'Role Type' },
                    { key: 'status', label: 'Status' }
                  ].map(({ key, label }) => (
                    <th key={key} onClick={() => handleSort(key)}>
                      {label}
                      <FontAwesomeIcon
                        icon={sortField === key ? (sortDirection === 'asc' ? faSortUp : faSortDown) : faSort}
                        className="sort-icon"
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentEnterprises.map((enterprise) => (
                  <tr key={enterprise.eid}>
                    <td className="copyable-cell">
                      <span title={enterprise.eid}>{enterprise.eid}</span>
                      <FontAwesomeIcon
                        icon={faCopy}
                        className="copy-icon"
                        onClick={() => handleCopy(enterprise.eid, 'Ent. ID')}
                      />
                    </td>
                    <td>{enterprise.ent_name}</td>
                    <td className="centered">{enterprise.org_type}</td>
                    <td className="copyable-cell">
                      <span title={enterprise.vaid}>{enterprise.vaid}</span>
                      <FontAwesomeIcon
                        icon={faCopy}
                        className="copy-icon"
                        onClick={() => handleCopy(enterprise.vaid, 'VA ID')}
                      />
                    </td>
                    <td>{enterprise.ospids.join(', ')}</td>
                    <td className="centered">{enterprise.role_type}</td>
                    <td className="centered">
                      <button
                        onClick={() => handleVerifyEnterprise(enterprise.eid, enterprise.status)}
                        className={enterprise.status === 'verified' ? 'status-verified' : 'status-unverified'}
                      >
                        {enterprise.status === 'verified' ? 'Verified' : 'Unverified'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            {Array.from({ length: Math.ceil(enterprises.length / enterprisesPerPage) }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => paginate(i + 1)}
                className={currentPage === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default RegisteredEnterprises;
